import { GetRequestParams } from '@/ship/Requests/RequestParams';
import { AxiosResponse } from 'axios';
import ITransformedValue from '@/ship/Values/ITransformedValue';
import Http from '@/ship/Http';
import { IReplacement, IReplacementEditModel } from '@/ship/Models/IReplacement';

export const getReplacements = (
    params?: GetRequestParams,
): Promise<AxiosResponse<ITransformedValue<IReplacement[]>>> => {
    return Http.get(`affil/users`, { params });
};

export const getReplacement = (id: number | string): Promise<AxiosResponse<ITransformedValue<IReplacement>>> => {
    return Http.get(`affil/users/${id}`);
};

export const removeReplacement = (id: number | string): Promise<AxiosResponse<ITransformedValue<IReplacement>>> => {
    return Http.get(`affil/users/${id}/delete`);
};

export const createReplacement = (
    data: IReplacementEditModel,
): Promise<AxiosResponse<ITransformedValue<IReplacement>>> => {
    return Http.post(`affil/users/by-id`, data);
};

export const updateReplacement = (
    id: number | string,
    data: IReplacementEditModel,
): Promise<AxiosResponse<ITransformedValue<IReplacement>>> => {
    return Http.post(`affil/users/${id}`, data);
};

export const endReplacement = (id: number | string): Promise<AxiosResponse<ITransformedValue<IReplacement>>> => {
    return Http.get(`affil/users/${id}/end`);
};
